import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "gatsby";
import { BREAKPOINTS, MAX_WIDTH_PX } from "../consts";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { StaticImage, getSrc } from "gatsby-plugin-image";
import UnderLinedLink from "./underlined-link";
import { useMediaQuery } from "react-responsive";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Lato';
    font-weight: 300;
    font-display: swap;
    font-style: normal;
    src: url(/fonts/Lato-Light.woff2) format("woff2"),
      url(/fonts/Lato-Light.woff) format("woff"),
      url(/fonts/Lato-Light.ttf) format("truetype");
  }

  @font-face {
    font-family: 'Lato';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
    src: url(/fonts/Lato-Regular.woff2) format("woff2"),
      url(/fonts/Lato-Regular.woff) format("woff"),
      url(/fonts/Lato-Regular.ttf) format("truetype");
  }

  @font-face {
    font-family: 'Epilogue';
    font-display: swap;
    font-style: normal;
    src: url(/fonts/Epilogue-VariableFont_wght.woff) format("woff"),
      url(/fonts/Epilogue-VariableFont_wght.ttf) format("truetype");
  }

  html {
    font-size: 26px;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
  }
  body {
    color: #080808;
    background: #FAFAFA;
    margin: 0;
    padding: 0;
    letter-spacing: -0.2px;
    overflow-x: hidden;
    a {
      color: black;
      text-decoration: none;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
      }
      &.underline {
        text-decoration: underline;
      }
    }
    h1, h2, h3, h4 {
      margin: 0 0 1.5rem ;
      font-weight: 500;
      font-family: "Epilogue";
      line-height: 3.6rem;
      margin-bottom: 1.2rem;
    }
    h1, .h1 {
      font-size: 3.2rem;
    }
    h2, .h2 {
      font-size: 3.1rem;
      line-height: 3.2rem;
    }
    h3, .h3 {
      font-size: 1.1rem;
      line-height: 1.4rem;
    }

    p,div.p {
      margin: 0 0 1.5rem;
      font-weight: 300;
      a {
        text-decoration: underline;
      }
    }
    button {
      font-family: 'Lato', sans-serif;
    }
  }
  svg {
    backface-visibility: hidden;
    transform: translateZ(0);
  }
  .break-line {
    word-break: break-word;
  }
  .header-text {
    color: ${(props) => (props.headerStyle === "black" ? "black" : "white")}; 
  }
  .logo-color {
    filter: ${(props) =>
      props.headerStyle === "black" ? "brightness(1) invert(1)" : "none"}; 
  }
  .header-background {
    background-color: ${(props) =>
      props.headerStyle === "black" ? "black" : "white"}; 
  }

  #mobile-menu-toggle {
    display: none;
  }

  @media screen and (max-width: ${BREAKPOINTS["tablet"]}px) {
    html {
      font-size: 20px;
      h1, h2, h3, h4 {
        line-height: 2.8rem;
      }
      h3 {
        font-size: 1.55rem;
      }
      #menu {
        display: none;
      }
      #mobile-menu-toggle {
        display: block;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    html {
      font-size: 18px;
      h1, h2, h3, h4 {
        line-height: 2.6rem;
      }
      h3 {
        font-size: 1.45rem;
      }
      #menu {
        display: none;
      }
      #mobile-menu-toggle {
        display: block;
      }
      h2 {
        font-size: 2rem;
      }
    }
  }
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  height: auto;
  width: 100%;
  a {
    color: white;
  }
`;
const HeaderContent = styled.header`
  width: 100%;
  height: 90px;
  max-width: ${MAX_WIDTH_PX}px;
  padding: 0 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    padding: 0 1.8rem;
    height: 70px;
  }
`;

const LogoContainer = styled.div`
  width: 170px;
  a {
    width: 100%;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    width: 130px;
  }
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  margin-left: 2rem;
  font-size: 0.775rem;
  a {
    color: ${(props) => (props.headerStyle === "black" ? "black" : "white")};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const MobileMenuToggle = styled.div`
  width: 20px;
  display: none;
  cursor: pointer;
  > div {
    height: 2px;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 3px;
    background: ${(props) => props.backgroundColor};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MobileMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  background: white;
  z-index: 100;
  text-align: center;
`;

const MobileMenuHeader = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 1.8rem;
  justify-content: space-between;
  padding: 0 1.8rem;
`;

const MobileMenu = styled.ul`
  list-style: none;
  margin: 2.5rem 0;
  padding: 0;
  color: black;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MobileMenuItem = styled.li`
  margin: 0.2rem;
  padding: 0.5rem;
  font-size: 1.1rem;
  a {
    color: black;
    text-decoration: none;
  }
`;

const CloseMenuButton = styled.div`
  width: 20px;
  height: 20px;
`;

export default function Layout({ title, children }) {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  const data = useStaticQuery(graphql`
    query HomePageQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
          rootUrl
        }
      }
      socialSharingImage: file(name: { eq: "SocialSharing" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `);

  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const path = location.pathname.replaceAll("/", "");

  const pagesWhichNeedBlack = ["company", "infrastructure", "media-kit"];
  const color = pagesWhichNeedBlack.includes(path) ? "black" : "white";

  const [headerStyle] = useState(color);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const socialSharingImageSrc = `${data.site.siteMetadata.rootUrl}${getSrc(data.socialSharingImage)}`;

  const isProduction = process.env.NODE_ENV !== 'development';

  return (
    <>
      <GlobalStyle headerStyle={headerStyle} />

      <Helmet>
        <title>
          {data.site.siteMetadata.title}
          {title ? ` - ${title}` : ``}
        </title>
        <meta name="description" content={data.site.siteMetadata.description} />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>

        <link
          rel="preload"
          href="/fonts/Epilogue-VariableFont_wght.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
          key="Epilogue-VariableFont_wght"
        />
        <link
          rel="preload"
          href="/fonts/Lato-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          key="Lato-Light"
        />
        <link
          rel="preload"
          href="/fonts/Lato-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          key="Lato-Regular"
        />

        {isProduction && <script src="https://cdn.usefathom.com/script.js" data-spa="auto" data-site="NOTJBRUF" defer />}

        <meta property="og:type" content="website" />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta property="og:image" content={socialSharingImageSrc} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={data.site.siteMetadata.siteUrl} />
        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta name="twitter:description" content={data.site.siteMetadata.description} />
        <meta name="twitter:image" content={socialSharingImageSrc} />
        <meta name="twitter:site" content="@TowoLabs" />
        <meta name="twitter:creator" content="@TowoLabs" />

        <html lang="en" />
      </Helmet>

      <HeaderContainer>
        <HeaderContent>
          <LogoContainer>
            <Link to="/">
              <img
                className="logo-color"
                src="/static-images/Logo.png"
                alt="TowoLabs"
              />
            </Link>
          </LogoContainer>

          <MobileMenuToggle
            id="mobile-menu-toggle"
            backgroundColor={headerStyle}
            onClick={toggleMobileMenu}
          >
            <div></div>
            <div></div>
            <div></div>
          </MobileMenuToggle>

          <Menu id="menu" className="header-text">
            <MenuItem headerStyle={headerStyle}>
              <UnderLinedLink
                to="/#products"
                internal={true}
                arrow={false}
                color={headerStyle}
              >
                Products
              </UnderLinedLink>
            </MenuItem>
            <MenuItem headerStyle={headerStyle}>
              <UnderLinedLink
                to="/infrastructure/"
                internal={true}
                arrow={false}
                color={headerStyle}
              >
                Infrastructure
              </UnderLinedLink>
            </MenuItem>
            <MenuItem headerStyle={headerStyle}>
              <UnderLinedLink
                to="/company/"
                internal={true}
                arrow={false}
                color={headerStyle}
              >
                Company
              </UnderLinedLink>
            </MenuItem>
          </Menu>

          {mobileMenuOpen && (
            <MobileMenuContainer>
              <MobileMenuHeader>
                <Link to="/">
                  <img
                    style={{
                      filter: "brightness(1) invert(1)",
                      width: "130px"
                    }}
                    src="/static-images/Logo.png"
                    alt="TowoLabs"
                  />
                </Link>
                <CloseMenuButton role="button" onClick={toggleMobileMenu}>
                  <StaticImage
                    src="../images/CloseCross.png"
                    alt=""
                    width={20}
                    quality={100}
                    placeholder="none"
                  />
                </CloseMenuButton>
              </MobileMenuHeader>
              <MobileMenu>
                <MobileMenuItem
                  headerStyle={headerStyle}
                  onClick={toggleMobileMenu}
                >
                  <Link to="/#products">Products</Link>
                </MobileMenuItem>
                <MobileMenuItem
                  headerStyle={headerStyle}
                  onClick={toggleMobileMenu}
                >
                  <Link to="/infrastructure/">Infrastructure</Link>
                </MobileMenuItem>
                <MobileMenuItem
                  headerStyle={headerStyle}
                  onClick={toggleMobileMenu}
                >
                  <Link to="/company/">Company</Link>
                </MobileMenuItem>
              </MobileMenu>
            </MobileMenuContainer>
          )}
        </HeaderContent>
      </HeaderContainer>

      {children}
    </>
  );
}
