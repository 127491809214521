import React from "react";
import styled from "styled-components";
import { CONTENT_BOX_PADDING } from "../consts";
import ContentWrapper from "./content-wrapper";

const Container = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  min-height: ${(props) =>
    props.fullHeight
      ? `calc(100vh);`
      : props.viewHeight
      ? `${props.viewHeight}vh`
      : `auto`};
  padding: ${(props) =>
    props.padding && !props.noPadding
      ? `${CONTENT_BOX_PADDING}rem 0 ${
          props.noBottomPadding === true ? 0 : CONTENT_BOX_PADDING
        }rem;`
      : `0`};
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "inital"};
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  text-align: ${(props) => (props.textAlign ? props.textAlign : `left`)};
  justify-content: ${(props) =>
    props.justifyContent === "left" ? `flex-start` : `flex-end`};
  padding: ${(props) =>
    props.noPadding ? `${CONTENT_BOX_PADDING}rem 0;` : `0`};
  box-sizing: border-box;
  overflow: ${(props) => (props.hideOverflow ? "hidden" : "initial")};
  border-top: ${(props) => (props.topBorder ? "2px solid #080808" : "none")};
  border-bottom: ${(props) =>
    props.bottomBorder ? "2px solid #080808" : "none"};
  color: ${(props) => (props.textColor ? props.textColor : "inital")};
  a {
    color: ${(props) => (props.textColor ? props.textColor : "inital")};
  }
`;

export default function Block({
  hideOverflow = false,
  padding = true,
  id,
  textColor,
  justifyContent,
  textAlign,
  topBorder,
  bottomBorder,
  fullHeight,
  backgroundColor,
  viewHeight,
  children,
  noPadding,
  noBottomPadding,
}) {
  return (
    <Container
      id={id}
      padding={padding}
      backgroundColor={backgroundColor}
      fullHeight={fullHeight}
      viewHeight={viewHeight}
      noPadding={noPadding}
      noBottomPadding={noBottomPadding}
    >
      <ContentWrapper justifyContent={justifyContent}>
        <Content
          hideOverflow={hideOverflow}
          textColor={textColor}
          justifyContent={justifyContent}
          textAlign={textAlign}
          topBorder={topBorder}
          bottomBorder={bottomBorder}
        >
          {children}
        </Content>
      </ContentWrapper>
    </Container>
  );
}
