import React from "react";
import styled from "styled-components";
import ContentWrapper from "./content-wrapper";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BREAKPOINTS } from "../consts";

const Container = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : `#242424`};
  height: calc(100vh);
  min-height: 35rem;
  color: white;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  h1 {
    margin: 0;
    padding: 0;
    max-width: 34rem;
    display: flex;
    align-items: center;
    padding-top: 2rem;
    line-height: 4rem;
    font-family: "Epilogue";
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    h1 {
      height: calc(50vh);
      padding: 0;
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export default function Hero({ imageData, text, backgroundColor, children }) {
  const image = getImage(imageData);

  return (
    <Container backgroundColor={backgroundColor}>
      {imageData && (
        <ImageContainer>
          <GatsbyImage
            alt={text}
            image={image}
            layout="fullWidth"
            objectFit="cover"
            quality={90}
          />
        </ImageContainer>
      )}

      {text ? (
        <ContentWrapper paddingTop="3.88rem">
          <h1 className="header-text">
            {text}
          </h1>
        </ContentWrapper>
      ) : (
        <ContentWrapper paddingTop="3.88rem">{children}</ContentWrapper>
      )}
    </Container>
  );
}
