import * as React from "react";
import styled from "styled-components";
import Block from "./block";
import SplitFlexBox from "./split-flex-box";
import { StaticImage } from "gatsby-plugin-image";
import AnimatedEntry from "./animated-entry";
import UnderLinedLink from "../components/underlined-link";
import { useMediaQuery } from "react-responsive";
import { BREAKPOINTS } from "../consts";
import { Link } from "gatsby";

const FooterContainer = styled.footer`
  #company-footer {
    margin-top: 2rem;
  }
  #company-footer > div > div {
    padding: 0 0 1.5rem;
  }
`;
const TwitterLink = styled.a`
  margin: 1.4rem 0;
  display: inline-block;
  position: relative;
  .white-twitter-logo {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: opacity 0.2s ease-in-out 0s;
  }
  &:hover {
    .gatsby-image-wrapper {
      border-radius: 50%;
    }
    .black-twitter-logo {
      /* display: none; */
    }
    .white-twitter-logo {
      opacity: 1;
    }
  }
`;
const CompanyName = styled.div`
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  a {
    width: 150px;
    img {
      width: 150px;
      filter: brightness(1) invert(1);
    }
  }
`;
const CompanyDescription = styled.p`
  font-size: ${(props) => (props.isMobile ? "initial" : "0.7rem")};
  margin: 0;
`;
const Copyright = styled.div`
  color: #a2a3a5;
  font-weight: 300;
  font-size: ${(props) => (props.isMobile ? "1.1rem" : "0.7rem")};
  margin: 0;
`;
const LinksContainer = styled.div`
  width: 300px;
  display: flex;
  align-content: right;
  flex-direction: column;
  margin: ${(props) => (props.isMobile ? "1rem 0" : "0rem")};
  p {
    margin: 0 0 0.6rem;
    font-size: ${(props) => (props.isMobile ? "1.1rem" : ".9rem")};
    font-weight: 500;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      color: #a2a3a5;
      font-size: ${(props) => (props.isMobile ? "initial" : "0.7rem")};
      font-weight: 300;
      margin-bottom: ${(props) => (props.isMobile ? "0.3rem" : "0.2rem")};
      display: flex;
      align-items: center;
      position: relative;
      a {
        text-decoration: none;
        padding: ${(props) => (props.isMobile ? "0.3rem 0" : "0.1rem 0")};
        > span {
          margin-top: -0.1rem;
        }
      }
    }
  }
`;
const SayHiContainer = styled.div`
  text-align: center;
  width: 100%;
  h2 {
    font-weight: 600;
    display: inline-block;
    position: relative;
    left: -30px;
    font-size: 3.845rem;
    margin-bottom: 3.8rem;
    span {
      display: block;
      position: relative;
      left: 2rem;
      top: 1.2rem;
    }
  }
`;
const SayHiEmail = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Epilogue";
  margin-bottom: 0;
  a {
    text-decoration: none;
  }
`;

export default function Footer() {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  const date = new Date();

  return (
    <FooterContainer>
      <Block>
        <AnimatedEntry animation="line" />
      </Block>
      <Block noPadding={true}>
        <SayHiContainer>
          <h2>
            Say <span>hej!</span>
          </h2>
          <p>
            If you want to find out more or just say hello,
            {isMobile ? "" : <br />} you can contact us at:
          </p>
          <SayHiEmail>
            <a href="mailto:hello@towolabs.com">hello@towolabs.com</a>
          </SayHiEmail>
        </SayHiContainer>
      </Block>
      <Block noBottomPadding={true}>
        <AnimatedEntry animation="line" />
      </Block>
      <Block id="company-footer" noPadding={true}>
        <SplitFlexBox>
          <div>
            <CompanyName>
              <Link to="/">
                <img
                  className="logo-color"
                  src="/static-images/Logo.png"
                  alt="TowoLabs"
                />
              </Link>
            </CompanyName>
            <CompanyDescription isMobile={isMobile}>
              A software engineering company specialized in wallet development and blockchain infrastructure. 
              Our services are developed in accordance with the principles of privacy by design and data minimization.
            </CompanyDescription>
            <TwitterLink
              href="https://twitter.com/towolabs"
              rel="noreferrer"
              name="Twitter Profile"
              target="_blank"
              className={`twitter-logo-container`}
              aria-label="Visit Towo Labs on Twitter"
            >
              <StaticImage
                src="../images/Twitter.png"
                alt=""
                className="black-twitter-logo"
                width={40}
                quality={90}
                placeholder="none"
              />
              <StaticImage
                src="../images/TwitterWhite.png"
                alt=""
                className="white-twitter-logo"
                width={40}
                quality={90}
                placeholder="none"
              />
            </TwitterLink>
            {!isMobile && (
              <Copyright>© 2019-{date.getFullYear()} Towo Labs</Copyright>
            )}
          </div>
          <LinksContainer isMobile={isMobile}>
            <p>Links</p>
            <ul>
              <li>
                <UnderLinedLink
                  to="http://bifrostwallet.com/"
                  isMobile={isMobile}
                >
                  Bifrost Wallet
                </UnderLinedLink>
              </li>
              <li>
                <UnderLinedLink
                  to="http://bifrostoracle.com/"
                  isMobile={isMobile}
                >
                  Bifrost Oracle
                </UnderLinedLink>
              </li>
              <li>
                <UnderLinedLink
                  to="/infrastructure/"
                  internal={true}
                  arrow={false}
                >
                  Infrastructure
                </UnderLinedLink>
              </li>
              <li>
                <UnderLinedLink to="/company/" internal={true} arrow={false}>
                  Company
                </UnderLinedLink>
              </li>
              <li>
                <UnderLinedLink to="/media-kit/" internal={true} arrow={false}>
                  Media Kit
                </UnderLinedLink>
              </li>
            </ul>
          </LinksContainer>
          {isMobile && (
            <Copyright style={{ marginTop: "1rem" }}>
              © 2019-{date.getFullYear()} Towo Labs
            </Copyright>
          )}
        </SplitFlexBox>
      </Block>
    </FooterContainer>
  );
}
