import React from "react";
import styled from "styled-components";

const Container = styled.span`
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : `start`};
  white-space: pre;
  flex: 1;
`;

const BorderLineAnimationContainer = styled.div`
  background: #080808;
  height: 2px;
  width: 100%;
`;

export default function AnimatedEntry({
  animation,
  justifyContent,
}) {


  return (
    <>
      {animation === "line" && (
        <Container justifyContent={justifyContent}>
          <BorderLineAnimationContainer/>
        </Container>
      )}
    </>
  );
}
