import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

// Generate new filters here: https://codepen.io/sosuke/pen/Pjoqqp
const getFilter = (color) => {
  switch (color) {
    case "white":
      return "invert(100%) sepia(0%) saturate(7472%) hue-rotate(196deg) brightness(100%) contrast(103%);";
    default:
      return "none";
  }
};

const Container = styled.span`
  a {
    display: inline-block;
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-decoration: none !important;
    flex-direction: column;

    div.line {
      position: relative;
      width: 0%;
      height: 2px;
      background-color: ${(props) => (props.color ? props.color : `black`)};
      bottom: -2px;
      transition: width 0.25s ease-out;
    }

    &:hover {
      div.line {
        width: 100%;
      }
    }
  }
`;

const ArrowContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 0.3rem;
  margin-top: 0.1rem;
  display: ${(props) => (props.hide ? `none;` : `inline-flex`)};
  img {
    filter: ${(props) => getFilter(props.color)};
  }
  .gatsby-image-wrapper {
    transform: ${(props) =>
      props.rotation ? `rotate(${props.rotation}deg);` : `rotate(-45deg);`};
  }
`;

export default function UnderLinedLink({
  internal,
  rotation,
  color,
  to,
  arrow = true,
  children,
  isMobile = false,
}) {
  return (
    <Container color={color} isMobile={isMobile}>
      {internal ? (
        <Link to={to}>
          <div>
            {children}

            <ArrowContainer
              rotation={rotation}
              color={color}
              hide={arrow === false}
            >
              {isMobile ? (
                <StaticImage
                  src="../images/Arrow.png"
                  alt=""
                  width={15}
                  quality={90}
                  placeholder="none"
                />
              ) : (
                <StaticImage
                  src="../images/Arrow.png"
                  alt=""
                  width={20}
                  quality={90}
                  placeholder="none"
                />
              )}
            </ArrowContainer>
          </div>
          <div className="line"></div>
        </Link>
      ) : (
        <a href={to}>
          <div>
            {children}
            <ArrowContainer
              rotation={rotation}
              color={color}
              hide={arrow === false}
            >
              {isMobile ? (
                <StaticImage
                  src="../images/Arrow.png"
                  alt=""
                  width={15}
                  quality={90}
                  placeholder="none"
                />
              ) : (
                <StaticImage
                  src="../images/Arrow.png"
                  alt=""
                  width={20}
                  quality={90}
                  placeholder="none"
                />
              )}
            </ArrowContainer>
          </div>
          <div className="line"></div>
        </a>
      )}
    </Container>
  );
}
