import React from "react";
import styled from "styled-components";
import { BREAKPOINTS, MAX_WIDTH_PX } from "../consts";

const Container = styled.div`
  max-width: ${MAX_WIDTH_PX}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 3rem;
  padding-top: ${props => props.paddingTop ? props.paddingTop : `0`};
  display: flex;
  min-height: 100%;
  justify-content: ${props => props.justifyContent ? props.justifyContent : `left`};
  z-index: 2;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    padding: 0 1.8rem;
    padding-top: ${props => props.paddingTop ? props.paddingTop : `0`};
  }
`;

export default function ContentWrapper({ justifyContent, paddingTop, children }) {
  return <Container justifyContent={justifyContent} paddingTop={paddingTop}>{children}</Container>;
}
